import './bootstrap';
import '../css/app.css';

// Import modules...
import {computed, createApp, h} from 'vue';
import {createInertiaApp, Link as InertiaLink, usePage} from '@inertiajs/vue3';
import simpleNotifications from '@dootix/dootix-vue-tailwind/src/modules/simple_notifications';
import { dvt_translate, dvt_get_locale, dvt_clean_source } from '@dootix/dootix-vue-tailwind/src/helpers.js'
import 'remixicon/fonts/remixicon.css'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import base from './base.js'
import * as Sentry from "@sentry/vue";
import {ChevronRightIcon} from '@heroicons/vue/24/outline';
import SimpleBreadcrumbs from '@dootix/dootix-vue-tailwind/src/components/navigation/SimpleBreadcrumbs.vue'
import SimpleButton from '@dootix/dootix-vue-tailwind/src/components/elements/buttons/SimpleButton.vue';
import SimpleCard from '@dootix/dootix-vue-tailwind/src/components/layout/SimpleCard.vue';
import SimpleModal from '@dootix/dootix-vue-tailwind/src/components/overlays/SimpleModal.vue';
import SimpleInput from "@dootix/dootix-vue-tailwind/src/components/forms/input_groups/SimpleInput.vue";
import SimpleMultiselect from "@dootix/dootix-vue-tailwind/src/components/forms/input_groups/SimpleMultiselect.vue";
import Breadcrumbs from "./Components/Breadcrumbs.vue";

const el = document.getElementById('app');
const appName = computed(() => usePage().props.appName)

const themeVariables = {
    badge: {
        styles: {
            badge: {
                state: "bg-purple-100 text-purple-700 border-purple-400 rounded-full border",
                warning: "bg-orange-100 text-orange-700 border-orange-400 rounded-full border",
                danger: "bg-red-100 text-red-800 border-red-400 rounded-full border",
                success: "bg-green-100 text-green-800 border-green-400 rounded-full border",

            },
        },
    },
    input: {
        styles: {
            success: "border-green-300 text-green-900 placeholder-green-300 focus:outline-none focus:ring-green-500 focus:border-green-500",
            help_text: {
                styles: {
                    success: "text-gray-500",
                } ,
            },
            label: {
                styles: {
                    success: "text-gray-700",
                    danger: "text-gray-700",
                },
            },
        },
    },
}

createInertiaApp({
    progress: {
        color: '#4B5563',
    },
    title: (title) => `${title} - ${appName}`,
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
            environment: import.meta.env.MODE,
            debug: import.meta.env.DEV,
            trackComponents: true,
            integrations: [new Sentry.BrowserTracing()],
        })

        app.mixin({ methods: { route } })
            .mixin(base)
            .mixin({
                methods: {
                    dvt_translate,
                    dvt_get_locale,
                    dvt_clean_source,
                },
            })
            .provide('themeVariables', themeVariables)
            .provide('simpleNotifications', simpleNotifications)
            .component('InertiaLink', InertiaLink)
            .component('ChevronRightIcon', ChevronRightIcon)
            .component('SimpleBreadcrumbs', SimpleBreadcrumbs)
            .component('SimpleButton', SimpleButton)
            .component('SimpleCard', SimpleCard)
            .component('SimpleModal', SimpleModal)
            .component('SimpleInput', SimpleInput)
            .component('SimpleMultiselect', SimpleMultiselect)
            .component('Breadcrumbs', Breadcrumbs)
            .mount(el);
    },
})
