<template>
    <SimpleBreadcrumbs :home-href="route('home')" :pages="pages">
        <template v-slot:home_a="{ href }">
            <Link :href="href" class="flex items-center">
                <i class="ri-home-3-line ri-lg" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </Link>
        </template>
        <template #a="{ page }">
            <Link :href="page.href">{{ page.name }}</Link>
        </template>
    </SimpleBreadcrumbs>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
    name: "Breadcrumbs",
    components: {
        Link,
    },
    props: {
        pages: { required: false, type: Object, default: () => {} }
    },
};
</script>
